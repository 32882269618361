import React from 'react';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Overdrive from 'react-overdrive';
import styled from 'react-emotion';

import { Layout, ProjectHeader, SEO } from 'components';
import config from '../../config/site';

const Info = ({ pageContext: { slug }, data: { headShot: { childImageSharp }, info: postNode } }) => {
  const info = postNode.frontmatter;

  return (
    <Layout>
      <Helmet title={`${info.title} | ${config.siteTitle}`} />
      <SEO postPath={slug} postNode={postNode} postSEO />
      <ProjectHeader
        avatar={childImageSharp.fluid}
        name={config.name}
        title={info.title}
        areas={info.areas}
        text={postNode.html}
      />
    </Layout>
  );
};

export default Info;

Info.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    info: PropTypes.object.isRequired,
    headShot: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }),
    }),
  }).isRequired,
};

Info.defaultProps = {
  pageContext: PropTypes.shape({
    next: null,
    prev: null,
  }),
};

export const pageQuery = graphql`
  query InfoPostBySlug($slug: String!) {
    headShot: file(relativePath: { eq: "headShot.png" }) {
      childImageSharp {
        fluid(maxWidth: 250, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    info: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        cover {
          childImageSharp {
            fluid(maxWidth: 4400, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 800) {
              src
            }
          }
        }
        date(formatString: "DD.MM.YYYY")
        title
        areas
      }
    }
  }
`;
